import { reverseGeocode } from '../lib/api';
import moment from "moment";
import { getCurrentPosition } from '../lib/utils';

export class LocationSyncing {
  static setup() {
    $(document).on('turbolinks:load', async function () {

      let storage = JSON.parse(localStorage.getItem(LocationSyncing.storageKey)) || {};

      if (LocationSyncing.isShowLocationPrompt()) {
        LocationSyncing.showLocationPrompt();
      }

      if (storage && storage.longitude && storage.latitude) {
        LocationSyncing.updateSessionLocation(storage);
      }

      $('#prompt-location-btn, #use-current-location').click( async () => {
        LocationSyncing.closeMoboMenu();
        const permissionStatus = !!navigator.permissions && await navigator.permissions.query({ name: 'geolocation' });

        if (
            !permissionStatus ||
            permissionStatus.state === 'prompt' ||
            (permissionStatus.state === 'granted')
        ) {
          storage = await LocationSyncing.promptForLocationPermission(storage);
        }

        if (storage && storage.longitude && storage.latitude) {
          LocationSyncing.updateSessionLocation(storage);
        }

        LocationSyncing.hideLocationPrompt();
        LocationSyncing.setShowLocationPrompt(false);
      })

      $('#close-location-prompt').click( function() {
        LocationSyncing.hideLocationPrompt();
        LocationSyncing.hideLocationPromptFor(10);
      })
    });
  }

  static showLocationPrompt() {
    $('body').addClass('location-prompt');
    $('.location-header').removeClass('d-none');
  }

  static hideLocationPrompt() {
    $('.location-header').hide();
    $('body').removeClass('location-prompt');
  }

  static closeMoboMenu() {
    let menu = $("#mobo-menu");
    if (menu.hasClass("mobo-menu-open")) {
      $("body").removeClass("mobo-menu-open");
      menu.removeClass("mobo-menu-open");
      $("#navbarCollapse-1").removeClass("show");
    }
  }

  static updateSessionLocation(storage) {
    let location = { latitude: storage.latitude, longitude: storage.longitude };
    $.ajax({
      url: `${process.env.API_HOST}/locations`,
      type: 'PUT',
      dataType: 'json',
      data: { location },
      success: function (response) {
        if (response.location_updated && response.refresh_page) {
          window.location.reload();
        }
      }
    });
  }

  static async promptForLocationPermission(storage) {
    try {
      const { coords: { latitude, longitude } } = await getCurrentPosition();
      storage.latitude = latitude;
      storage.longitude = longitude;
      storage.userSetLocation = true;
      storage.showLocationPrompt = false;
      const reversePlaceName = await reverseGeocode({ latitude, longitude });
      if (reversePlaceName && reversePlaceName.length) {
        storage.locationName = reversePlaceName;
        storage.currentLocationName = reversePlaceName;
        if (document.querySelector('.location-name')) {
          document.querySelector('.location-name').innerText = reversePlaceName;
        }
      }
    } catch (error) {}

    localStorage.setItem(LocationSyncing.storageKey, JSON.stringify(storage));
    return storage;
  }

  static setShowLocationPrompt(showLocationPrompt) {
    let storage = JSON.parse(localStorage.getItem(LocationSyncing.storageKey)) || {};
    storage.showLocationPrompt = showLocationPrompt;
    localStorage.setItem(LocationSyncing.storageKey, JSON.stringify(storage));
  }

  static isShowLocationPrompt() {
    let storage = JSON.parse(localStorage.getItem(LocationSyncing.storageKey)) || {};
    if(storage.hasOwnProperty('showLocationPrompt') && !storage.showLocationPrompt) {
      return false;
    } else if(storage.hasOwnProperty('hideLocationPromptFor') ) {
      let hideLocationPromptFor = moment(storage.hideLocationPromptFor);
      return moment() > hideLocationPromptFor;
    } else {
      return true;
    }
  }

  static hideLocationPromptFor(minutes) {
    let storage = JSON.parse(localStorage.getItem(LocationSyncing.storageKey)) || {};
    storage.hideLocationPromptFor = moment().add(minutes, 'minutes');
    localStorage.setItem(LocationSyncing.storageKey, JSON.stringify(storage));
  }

  static get storageKey() {
    return 'cannasaver-local-storage';
  }
}
