import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import "./CarouselAdService.scss";

class CarouselAdService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [],
      loading: true,
    };
  }
  async componentDidMount() {
    const { zone } = this.props;
    const res = await axios.get(
      `${process.env.API_HOST}/services/${zone}?services=3`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const { services } = res.data;
    this.setState({ services, loading: false });
  }
  render() {
    const { services, loading } = this.state;
    if (loading) {
      return <div></div>;
    }
    return (
      <div className="z-banner d-flex flex-row z-before-footer">
        {services.map((service, index) => (
          <a
            key={index}
            href={`/pages/${service.id}/advertise_service`}
            className="d-inline-block text-center"
            target={service.target}
            rel={service.rel}
          >
            {service.image ? (
              <img
                src={service.image}
                title={service.title}
                alt={service.title}
              />
            ) : (
              <p>{service.title}</p>
            )}
          </a>
        ))}
      </div>
    );
  }
}
CarouselAdService.propTypes = {
  zone: PropTypes.string.isRequired,
};

export default CarouselAdService;
