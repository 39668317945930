import React from 'react';
import Slider from 'react-slick';
import CarouselAdService from './CarouselAdService';

const responsive = [
    {
        breakpoint: 2024,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
        },
    },
    {
        breakpoint: 1050,
        settings: {
            slidesToShow: 3.5,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
        },
    },
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
        },
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
        },
    },
    {
        breakpoint: 1,
        settings: {
            slidesToShow: 1.1,
            slidesToScroll: 1,
            infinite: false,
            dots: false,
        },
    },
];
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object

export default function AdCarouselFooter() {
    return (
        <div className="ad-carousel-container">
            <div id="ad-carousel" className="footer-carousel">
                <div className="mt-2">
                    <div>
                        <Slider
                            dots={false}
                            speed={500}
                            slidesToShow={3}
                            slidesToScroll={1}
                            responsive={responsive}
                            infinite={false}
                        >
                            <CarouselAdService zone={'Fixed-Footer-1'} />
                            <CarouselAdService zone={'Fixed-Footer-2'} />
                            <CarouselAdService zone={'Fixed-Footer-3'} />
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}
