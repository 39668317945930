import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "./CouponCarousel.scss";
import Coupon from "./Coupon";

const responsive = [
  {
    breakpoint: 2024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 1050,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2.5,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.5,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
];
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object

export default function CouponCarousel({ coupons = [] }) {
  let extra = coupons.length - 4;
  if (extra < 0) {
    extra = extra * -1;
  }
  return (
    <div className="carousel-wrapper">
      <Slider
        dots={false}
        speed={500}
        slidesToShow={4}
        slidesToScroll={1}
        responsive={responsive}
        infinite={false}
      >
        {coupons.map((coupon) => (
          <div key={coupon.id} className="card-wrapper">
            <Coupon coupon={coupon} />
          </div>
        ))}
        {Array(extra).map((emptyCoupon, i) => (
          <div key={i} className="card-wrapper">
            <Coupon coupon={{}} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
