import React, { useState, useEffect } from "react";
import { SkeletonLoader } from "../lib/utils";
import { fetchCouponsFiltered } from "../lib/api";
import SmallStackedCouponCarousel from "./SmallStackedCouponCarousel";

export default function HomePageNearMeDeals({
  latitude,
  longitude,
  locationName,
}) {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiCallsWithCoordinates({ latitude, longitude });
  }, [longitude, latitude]);

  const apiCallsWithCoordinates = async ({ latitude, longitude }) => {
    setLoading(true);
    const { coupons: newCoupons } = await fetchCouponsFiltered({
      filters: {},
      props: { longitude, latitude },
    });
    setCoupons(newCoupons);
    setLoading(false);
  };

  if (loading) {
    return (
      <div className={"container"}>
        <SkeletonLoader height={200} />
      </div>
    );
  }
  return (
    <section
      className={
        "horizontal-card bg-light py-4 py-md-8 no-gutters d-block pb-0"
      }
    >
      <div className="container">
        <h2 className="section-header">
          Deals Near Me
          <a
            href="/marijuana-deals-near-me"
            className="text-muted ml-auto view-all-link float-right"
          >
            View All
          </a>
        </h2>
        <div className="py-2">
          <SmallStackedCouponCarousel coupons={coupons} />
        </div>
      </div>
    </section>
  );
}
