import React from "react";
import Storage from "./Storage";
import LocationSearch from "./LocationSearch";
import ServiceAd from "./ServiceAd";
import HomePageFeatured from "./HomePageFeatured";
import HomePageNearMeDeals from "./HomePageNearMeDeals";
import HomePageStores from "./HomePageStores";
import HomePageCategoryCoupons from "./HomePageCategoryCoupons";
import HomePageCustomCoupons from "./HomePageCustomCoupons";

export default function HomePagePart1() {
  return (
    <Storage>
      <LocationSearch />
      <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
        <div className="container">
          <ServiceAd zone={"Top-B(600*100)"} />
        </div>
      </div>
      <HomePageFeatured />
      <HomePageNearMeDeals />
      <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
        <div className="bg-white pb-2">
          <div className="container">
            <ServiceAd zone={"Above-420-Activities"} />
          </div>
        </div>
      </div>
      <HomePageStores />
      <div className="d-none d-md-block d-lg-block d-xl-block">
        <div className="bg-grey pb-4 pt-1">
          {/*    Desktop Spacer */}
        </div>
      </div>
      <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
          <div className="bg-grey pb-4 pt-2">
              <div className="container">
                  <ServiceAd zone={"Before-Best-Of"}/>
              </div>
          </div>
      </div>
      <HomePageCategoryCoupons
        slug={"wax-concentrates-deals"}
        title={"BEST CONCENTRATE DEALS"}
        href={"/categories/wax-concentrates-deals"}
      />
      <HomePageCustomCoupons
        title={"MEDICAL MARIJUANA DISPENSARY DEALS"}
        href={"/medical-marijuana-deals"}
        endpoint={"medical_marijuana"}
      />
      <HomePageCategoryCoupons
        slug={"edible-deals"}
        title={"BEST EDIBLE DEALS"}
        href={"/categories/edible-deals"}
      />
      <HomePageCustomCoupons
        title={"BEST RECREATIONAL DISPENSARY DEALS"}
        href={"/recreational-marijuana-deals"}
        endpoint={"recreational_marijuana"}
      />
    </Storage>
  );
}
