document.addEventListener("turbolinks:load", function() {
  var add_item_to_xvirtual_scroll,
    add_item_val_to_xvirtual_scroll,
    clear_xvertual_scroll,
    highest_time,
    init_autocomplete_search_business;
  highest_time = function(times) {
    var high_time, index;
    index = 0;
    high_time = 0;
    while (index < times.length) {
      if (times[index] > high_time) {
        high_time = times[index];
      }
      index += 1;
    }
    return high_time;
  };
  clear_xvertual_scroll = function(id, current_obj) {
    current_obj
      .closest(".search-head")
      .find(".xvs_change_business")
      .css("height", "200px");
    current_obj
      .closest(".search-head")
      .find(".all_suggestion")
      .html("");
    current_obj
      .closest(".search-head")
      .find(".deal_suggestion")
      .html("");
    current_obj
      .closest(".search-head")
      .find(".brand_suggestion")
      .html("");
    current_obj
      .closest(".search-head")
      .find(".store_suggestion")
      .html("");
    current_obj
      .closest(".search-head")
      .find(".xvs_change_business .xvirtual_load")
      .show();
  };
  add_item_to_xvirtual_scroll = function(id, content) {
    var new_tag, stack_scroll, xvs;
    xvs = $("#" + id).first();
    stack_scroll = xvs.html();
    new_tag =
      '<div class="item" data-content="' + content + '">' + content + "</item>";
    stack_scroll += new_tag;
    return xvs.html(stack_scroll);
  };
  add_item_val_to_xvirtual_scroll = function(
    id,
    content,
    val,
    class_name,
    curobj,
    url,
  ) {
    var new_tag, stack_scroll, xvs;
    xvs = curobj
      .closest(".search-head")
      .find("." + id)
      .first();
    stack_scroll = xvs.html();
    new_tag =
      '<div class="item ' + class_name + '" data-val="' + val + '" data-url="' + url + '">' +
      content +
      "</div>";
    stack_scroll += new_tag;
    return xvs.html(stack_scroll);
  };
  init_autocomplete_search_business = function() {
    var change_location_input,
      in_progress,
      in_progresses,
      last_location_predictions_lookup;
    in_progress = false;
    in_progresses = [];
    change_location_input = $(".main-search");
    last_location_predictions_lookup = change_location_input.val();
    change_location_input.bind("change click keyup input paste", function() {
      var current_time, current_val, current_obj;
      current_obj = $(this);
      current_val = current_obj.val();
      if (
        last_location_predictions_lookup === "" ||
        current_val !== last_location_predictions_lookup
      ) {
        if (current_val.length > 2 && !in_progress) {
          current_time = Date.now();
          in_progresses.push(current_time);
          setTimeout(function() {
            var high_time;
            high_time = highest_time(in_progresses);
            if (high_time === current_time) {
              return $.ajax("/search_suggestion.json", {
                method: "GET",
                data: { query: current_val },
                success: function(data) {
                  var final_html,
                    index,
                    item_height,
                    deal_items,
                    loc_cities_items,
                    loc_zips_items,
                    brand_items,
                    store_items,
                    stack_html,
                    xscroll_height;
                  last_location_predictions_lookup = current_val;
                  clear_xvertual_scroll("xvs_change_business", $(this));
                  current_obj
                    .closest(".search-head")
                    .find(".all_suggestion")
                    .html("");
                  current_obj
                    .closest(".search-head")
                    .find(".deal_suggestion")
                    .html("");
                  current_obj
                    .closest(".search-head")
                    .find(".brand_suggestion")
                    .html("");
                  current_obj
                    .closest(".search-head")
                    .find(".store_suggestion")
                    .html("");
                  current_obj
                    .closest(".search-head")
                    .find(".location_suggestion")
                    .html("");
                  deal_items = data.coupon_suggestions;
                  brand_items = data.brand_suggestions;
                  store_items = data.store_suggestions;

                  loc_cities_items = data.location_cities_suggestions;
                  loc_zips_items = data.location_zips_suggestions;

                  index = 0;
                  while (index < deal_items.length) {
                    stack_html = deal_items[index].value.split(" | ");
                    final_html = "";
                    final_html += "<p>" + stack_html[0] + "</p>";
                    // final_html += "<p>Name: " + stack_html[0] + "</p>";
                    // final_html += "<p>Address: " + stack_html[1] + "</p>";
                    // final_html += "<p>Category: " + stack_html[2] + "</p>";
                    if (index == 0) {
                      current_obj
                        .closest(".search-head")
                        .find(".all_suggestion")
                        .append("<h4 class='title'>Deals</h4>");
                    }
                    add_item_val_to_xvirtual_scroll(
                      "all_suggestion",
                      final_html,
                      deal_items[index].data,
                      "deal-item",
                      current_obj
                    );
                    add_item_val_to_xvirtual_scroll(
                      "deal_suggestion",
                      final_html,
                      deal_items[index].data,
                      "deal-item",
                      current_obj
                    );
                    index += 1;
                  }

                  index = 0;
                  while (index < brand_items.length) {
                    stack_html = brand_items[index].value.split(" | ");
                    final_html = "";
                    final_html += "<p>" + stack_html[0] + "</p>";
                    if (index == 0) {
                      current_obj
                        .closest(".search-head")
                        .find(".all_suggestion")
                        .append("<h4 class='title'>Brands</h4>");
                    }
                    add_item_val_to_xvirtual_scroll(
                      "all_suggestion",
                      final_html,
                      brand_items[index].data,
                      "brand-item",
                      current_obj
                    );
                    add_item_val_to_xvirtual_scroll(
                      "brand_suggestion",
                      final_html,
                      brand_items[index].data,
                      "brand-item",
                      current_obj
                    );
                    index += 1;
                  }

                  index = 0;
                  while (index < store_items.length) {
                    stack_html = store_items[index].value.split(" | ");
                    final_html = "";
                    final_html += "<p>" + stack_html[0] + "</p>";
                    if (index == 0) {
                      current_obj
                        .closest(".search-head")
                        .find(".all_suggestion")
                        .append("<h4 class='title'>Stores</h4>");
                    }
                    add_item_val_to_xvirtual_scroll(
                      "all_suggestion",
                      final_html,
                      store_items[index].data,
                      "store-item",
                      current_obj
                    );
                    add_item_val_to_xvirtual_scroll(
                      "store_suggestion",
                      final_html,
                      store_items[index].data,
                      "store-item",
                      current_obj
                    );
                    index += 1;
                  }

                  index = 0;
                  if (
                    loc_zips_items.length > 0 ||
                    loc_cities_items.length > 0
                  ) {
                    current_obj
                      .closest(".search-head")
                      .find(".all_suggestion")
                      .append("<h4 class='title'>Locations</h4>");
                  }
                  while (index < loc_cities_items.length) {
                    var city = loc_cities_items[index]
                    stack_html = city.value.split(" | ");
                    final_html = "";
                    final_html += "<p>" + stack_html[0] + "</p>";

                    add_item_val_to_xvirtual_scroll(
                      "all_suggestion",
                      final_html,
                      stack_html[0],
                      "city-item",
                      current_obj,
                      city.url
                    );
                    add_item_val_to_xvirtual_scroll(
                      "location_suggestion",
                      final_html,
                      stack_html[0],
                      "city-item",
                      current_obj,
                      city.url
                    );
                    index += 1;
                  }

                  index = 0;
                  while (index < loc_zips_items.length) {
                    stack_html = loc_zips_items[index].value.split(" | ");
                    final_html = "";
                    final_html += "<p>" + stack_html[0] + "</p>";

                    add_item_val_to_xvirtual_scroll(
                      "all_suggestion",
                      final_html,
                      stack_html[0],
                      "location-item",
                      current_obj
                    );
                    add_item_val_to_xvirtual_scroll(
                      "location_suggestion",
                      final_html,
                      stack_html[0],
                      "location-item",
                      current_obj
                    );
                    index += 1;
                  }

                  if (
                    deal_items.length === 0 &&
                    brand_items.length === 0 &&
                    store_items.length === 0 &&
                    loc_cities_items.length === 0 &&
                    loc_zips_items.length === 0
                  ) {
                    current_obj
                      .closest(".search-head")
                      .find(".xvs_change_business .xvirtual_load")
                      .hide();
                    current_obj
                      .closest(".search-head")
                      .find(".xvs_change_business")
                      .css("height", "200px");
                    current_obj
                      .closest(".search-head")
                      .find(".all_suggestion")
                      .html("No Result Found...");
                  } else {
                    current_obj
                      .closest(".search-head")
                      .find(".xvs_change_business .xvirtual_load")
                      .hide();
                    item_height = current_obj
                      .closest(".search-head")
                      .find(".xvs_change_business .item")
                      .css("height");
                    item_height = parseInt(item_height.replace("px", ""));
                    xscroll_height = item_height * deal_items.length;
                    if (xscroll_height > 200) {
                      xscroll_height = 200;
                    }
                    current_obj
                      .closest(".search-head")
                      .find(".xvs_change_business")
                      .css("height", xscroll_height + "px");
                  }
                  current_obj
                    .closest(".search-head")
                    .find(".xvs_change_business .item")
                    .unbind();
                  return current_obj
                    .closest(".search-head")
                    .find(".xvs_change_business .item")
                    .bind("mousedown", function() {
                      var stack_content;
                      stack_content = $(this).data("val");
                      $(".auto_listing_name.form-control.lid").val(
                        stack_content
                      );
                      current_obj
                        .closest(".search-head")
                        .find(".xvs_change_location")
                        .hide();
                      // in_progress = true;
                      if ($(this).hasClass("deal-item")) {
                        window.location = "/coupon/" + stack_content;
                      } else if ($(this).hasClass("brand-item")) {
                        window.location = "/brands/" + stack_content;
                      } else if ($(this).hasClass("store-item")) {
                        window.location = "/deals/" + stack_content;
                      } else if ($(this).hasClass("city-item")) {
                        window.location = $(this).data("url");
                      } else if ($(this).hasClass("location-item")) {
                        window.location = "/search?query=" + stack_content;
                      }
                    });
                },
              });
            }
          }, 400);
          return current_obj
            .closest(".search-head")
            .find(".xvs_change_business")
            .show();
        } else {
          return clear_xvertual_scroll("xvs_change_business", $(this));
        }
      }
    });
    // return change_location_input.bind('blur', function() {
    //     return $(this).closest('.search-head').find('.xvs_change_business').hide();
    // });
    return $(document).click(function(event) {
      var $target = $(event.target);
      if (
        !$target.closest(".xvs_change_business").length &&
        $(".xvs_change_business").is(":visible")
      ) {
        return $(".xvs_change_business").hide();
      }
    });
  };
  return init_autocomplete_search_business();
});
