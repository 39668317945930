import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import styles from "./SmallStackedCouponCarousel.scss";
import { responsiveBreakpoints } from "../lib/utils";
import { useMediaQuery } from "react-responsive";

const CouponLink = ({ coupon }) => {
  return (
    <div className="coupon-link media rounded border-0 p-0 align-items-center bg-white">
      <a
        href={`/coupon/${coupon.slug}`}
        className="coupon-link-a d-flex flex-column justify-content-center h-100"
        title={coupon.name}
      >
        <img
          src={coupon.deal_image}
          className="card-img-top"
          alt={coupon.name}
          title={coupon.name}
          lazy="true"
        />
      </a>
      <div className="media-body px-1">
        <h5 className="mb-1">
          <a
            href={`/coupon/${coupon.slug}`}
            className="link-dark"
            title={coupon.name}
            className={"coupon-name"}
          >
            {coupon.name.length > 40 ? coupon.name.slice(0, 40) : coupon.name}
          </a>
        </h5>
        {coupon.store_location && (
          <p className="coupon-city">{`${coupon.store_location.city} - ${coupon.medical_or_recreational}`}</p>
        )}
        {coupon.distance && (
          <small className="coupon-city">{coupon.distance.toFixed(2)} mi</small>
        )}
      </div>
    </div>
  );
};

export default function SmallCouponCarousel({ coupons }) {
  return (
    <div className="carousel-wrapper">
      <Slider
        dots={false}
        speed={500}
        slidesToShow={4}
        slidesToScroll={1}
        responsive={responsiveBreakpoints}
      >
        {coupons.map((coupon, i) => (
          <div className="pl-0 py-0 my-2 pr-3" key={i}>
            <CouponLink coupon={coupon} />
          </div>
        ))}
      </Slider>
    </div>
  );
}
