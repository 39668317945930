import React from "react";
import PropTypes from "prop-types";

class CouponsLoadMore extends React.Component {
  constructor(props) {
    super(props);
  }
}

export default CouponsLoadMore;
