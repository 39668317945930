import React from "react";
import Slider from "react-slick";
import CarouselAdService from "./CarouselAdService";

const responsive = [
  {
    breakpoint: 2024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 1050,
    settings: {
      slidesToShow: 3.5,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2.2,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 2.2,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
  {
    breakpoint: 1,
    settings: {
      slidesToShow: 2.2,
      slidesToScroll: 1,
      infinite: false,
      dots: false,
    },
  },
];
// You can unslick at a given breakpoint now by adding:
// settings: "unslick"
// instead of a settings object

export default function AdCarousel() {
  return (
    <div id="ad-carousel">
      <div className="mt-2">
        <div>
          <Slider
            dots={false}
            speed={500}
            slidesToShow={3}
            slidesToScroll={1}
            responsive={responsive}
            infinite={false}
          >
            <CarouselAdService zone={"Top-right"} />
            <CarouselAdService zone={"After Search"} />
            <CarouselAdService zone={"Third-Mobile-Ad"} />
            <CarouselAdService zone={"Fourth-Mobile-Ad"} />
          </Slider>
        </div>
      </div>
    </div>
  );
}
