import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';
import { isNil } from 'lodash';

export const getCurrentPosition = () => {
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export const SkeletonLoader = ({ height }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' });
  if (isNil(height)) {
    height = 200;
  }
  if (isDesktop) {
    return (
      <SkeletonTheme color="lightGray" className="w-100">
        <div className="row w-100">
          <div className="col">
            <Skeleton height={height} width={`100%`} />
          </div>
          <div className="col">
            <Skeleton height={height} width={`100%`} />
          </div>
          <div className="col">
            <Skeleton height={height} width={`100%`} />
          </div>
          <div className="col">
            <Skeleton height={height} width={`100%`} />
          </div>
        </div>
      </SkeletonTheme>
    );
  }
  return (
    <SkeletonTheme color="lightGray" className="w-100">
      <div className="row w-100">
        <div className="col">
          <Skeleton height={height} width={`100%`} />
        </div>
        <div className="col">
          <Skeleton height={height} width={`100%`} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export const responsiveBreakpoints = [
  {
    breakpoint: 2024,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: false,
      dots: false
    }
  },
  {
    breakpoint: 1050,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      dots: false
    }
  },
  {
    breakpoint: 800,
    settings: {
      slidesToShow: 2.2,
      slidesToScroll: 1,
      infinite: false,
      dots: false
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.2,
      slidesToScroll: 1,
      infinite: false,
      dots: false
    }
  }
];
