import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import styles from "./SmallStackedCouponCarousel.scss";
import { responsiveBreakpoints } from "../lib/utils";
import { useMediaQuery } from "react-responsive";

const CouponLink = ({ coupon }) => {
  return (
    <div className="coupon-link media rounded border-0 p-0 align-items-center bg-white">
      <a
        href={`/coupon/${coupon.slug}`}
        className="coupon-link-a d-flex flex-column justify-content-center h-100"
        title={coupon.name}
      >
        <img
          src={coupon.deal_image}
          className="card-img-top"
          alt={coupon.name}
          title={coupon.name}
          lazy="true"
        />
      </a>
      <div className="media-body px-1">
        <h5 className="mb-1">
          <a
            href={`/coupon/${coupon.slug}`}
            className="link-dark"
            title={coupon.name}
            className={"coupon-name"}
          >
            {coupon.name.length > 40 ? coupon.name.slice(0, 40) : coupon.name}
          </a>
        </h5>
        {coupon.store_location && (
          <p className="coupon-city">{`${coupon.store_location.city} - ${coupon.medical_or_recreational}`}</p>
        )}
        {coupon.distance && (
          <small className="coupon-city">{coupon.distance.toFixed(2)} mi</small>
        )}
      </div>
    </div>
  );
};

export default function SmallStackedCouponCarousel({ coupons }) {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });

  const groupCoupons = (coupons) => {
    const groupedCoupons = [];
    let batch = [];
    for (let i = 0; i < coupons.length; i++) {
      batch.push(coupons[i]);
      if (i % 2 == 1) {
        groupedCoupons.push(batch);
        batch = [];
      }
    }
    if (batch.length > 0) {
      groupedCoupons.push(batch);
    }
    return groupedCoupons;
  };

  return (
    <div className="carousel-wrapper">
      <Slider
        dots={false}
        speed={500}
        slidesToShow={4}
        slidesToScroll={1}
        responsive={responsiveBreakpoints}
      >
        {isDesktop
          ? coupons.map((coupon, i) => (
              <div className="pl-0 py-0 my-2 pr-3" key={i}>
                <CouponLink coupon={coupon} />
              </div>
            ))
          : groupCoupons(coupons).map((batch, i) => (
              <div className="grouped-coupon" key={i}>
                <div className="mb-2 px-0 py-0">
                  <CouponLink coupon={batch[0]} />
                </div>

                {batch[1] && (
                  <div className="mt-2 px-0 py-0">
                    <CouponLink coupon={batch[1]} />
                  </div>
                )}
              </div>
            ))}
      </Slider>
    </div>
  );
}
