import React from "react";
import { useMediaQuery } from "react-responsive";
import Coupon from "./Coupon";

export default function HideOverflowCoupons({
  featuredCoupons,
  coupons,
  hideDistance,
  showcased,
  storePage,
}) {
  const isDesktop = useMediaQuery({ query: "(min-width: 992px)" });
  const isTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const isLargeMobile = useMediaQuery({ query: "(min-width: 576px)" });
  const featuredCouponIds = featuredCoupons.map((featured) => featured.id);
  let filteredCoupons = coupons.filter(
    (coupon) => !featuredCouponIds.includes(coupon.id)
  );
  filteredCoupons = featuredCoupons.concat(filteredCoupons);
  let remainder = 0;
  if (isDesktop) {
    remainder = filteredCoupons.length % 4;
  } else if (isTablet) {
    remainder = filteredCoupons.length % 3;
  } else if (isLargeMobile) {
    remainder = filteredCoupons.length % 2;
  }

  if (!storePage && filteredCoupons.length > remainder) {
    filteredCoupons.splice(remainder * -1, remainder);
  }

  if (showcased) {
    filteredCoupons = filteredCoupons.sort((coupon) =>
      coupon.showcased ? -1 : 1
    );
  }
  return (
    <React.Fragment>
      {filteredCoupons.map((coupon) => (
        <div
          key={coupon.id}
          className="col-6 col-sm-6 col-md-4 col-lg-3 mb-3 d-flex flex-column align-items-center px-2"
        >
          <Coupon
            coupon={coupon}
            featured={featuredCouponIds.includes(coupon.id)}
            hideDistance={hideDistance}
            showcased={showcased}
          />
        </div>
      ))}
    </React.Fragment>
  );
}
