import React from "react";
import PropTypes from "prop-types";
import Coupon from "./Coupon";
import HideOverflowCoupons from "./HideOverflowCoupons";
import NearMeDealsFilter from "./NearMeDealsFilter";
import NearMeDealsTitle from "./NearMeDealsTitle";
import { merge, remove, uniq } from "lodash";
import {
  apiFilters,
  fetchCategories,
  fetchCouponsFiltered,
  fetchStores,
  fetchStoreDistance,
} from "../lib/api";
import { SkeletonLoader } from "../lib/utils";
import { useMediaQuery } from "react-responsive";

class NearMeDeals extends React.Component {
  constructor(props) {
    super(props);
    const filters = {};
    const clearFilters = {};
    if (props.filters !== null && typeof props.filters === "object") {
      Object.keys(props.filters).forEach((key) => {
        if (key === apiFilters.CATEGORIES || key === apiFilters.STORES) {
          if (!(key in filters)) {
            filters[key] = [];
          }
          filters[key].push(props.filters[key]);
          filters[key] = uniq(filters[key]);
          if (key === apiFilters.STORES) {
            clearFilters[key] = filters[key];
          }
        } else if (Object.values(apiFilters).includes(key)) {
          filters[key] = props.filters[key];
        }
      });
    }
    this.state = {
      categories: [],
      coupons: [],
      distance: null,
      featuredCoupons: [],
      filters,
      showInactiveHeader: false,
      hideHeader: false,
      inactiveCouponText: '',
      inactiveCouponSubText: '',
      loading: true,
      page: 1,
      stores: [],
      totalRecords: 0,
      clearFilters,
    };
  }

  async componentDidMount() {
    const categories = await fetchCategories();
    this.setState({ categories });
    await this._updateStoreDropDown();
    if (this.props.storePage) {
      await this._fetchStoreDistance();
    }
    await this._fetchCoupons();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.latitude !== this.props.latitude ||
      prevProps.longitude !== this.props.longitude
    ) {
      await this._fetchCoupons();
      if (this.props.storePage) {
        await this._fetchStoreDistance();
      }
      await this._updateStoreDropDown();
    }
  }

  _updateStoreDropDown = async () => {
    const { longitude, latitude } = this.props;
    const stores = await fetchStores({ latitude, longitude });
    this.setState({ stores });
  };

  _fetchStoreDistance = async () => {
    const { storeId, longitude, latitude } = this.props;
    const distance = await fetchStoreDistance({ storeId, latitude, longitude });
    this.setState({ distance });
  };

  setFilter = (newFilters) => () => {
    const { filters } = this.state;
    Object.keys(newFilters).forEach((key) => {
      if (key === apiFilters.CATEGORIES || key === apiFilters.STORES) {
        if (!(key in filters)) {
          filters[key] = [];
        }
        filters[key].push(newFilters[key]);
        filters[key] = uniq(filters[key]);
      } else if (key === apiFilters.PRICE) {
        if (!(key in filters)) {
          filters[key] = [];
        }
        filters[key].push(newFilters[key]);
        filters[key] = uniq(filters[key], "min");
      } else {
        filters[key] = newFilters[key];
      }
    });
    this.setState({ filters }, this._fetchCoupons);
  };

  removeFilter = ({ filterKey, id }) => () => {
    const { filters } = this.state;
    if (
      filterKey === apiFilters.CATEGORIES ||
      filterKey === apiFilters.STORES
    ) {
      remove(filters[filterKey], (value) => value === id);
      if (filters[filterKey].length === 0) {
        delete filters[filterKey];
      }
    } else if (filterKey === apiFilters.PRICE) {
      remove(filters[filterKey], ({ min }) => min === id);
      if (filters[filterKey].length === 0) {
        delete filters[filterKey];
      }
    } else {
      delete filters[filterKey];
    }
    this.setState({ filters }, this._fetchCoupons);
  };

  clearFilters = async () => {
    const { clearFilters } = this.state;
    const { longitude, latitude } = this.props;
    const filters = Object.assign({}, clearFilters);
    this.setState({ loading: true, filters });
    if (!this.props.storePage) {
      const { coupons: featuredCoupons } = await fetchCouponsFiltered({
        filters,
        props: { longitude, latitude, featured: true },
      });
      this.setState({ featuredCoupons });
    }
    const page = 1;
    const { coupons, totalRecords } = await fetchCouponsFiltered({
      filters,
      page,
      props: { longitude, latitude },
    });
    this.setState({
      coupons,
      page,
      filters,
      totalRecords,
      loading: false,
    });
  };

  _fetchCoupons = async () => {
    this.setState({ loading: true });
    const { filters } = this.state;
    const { longitude, latitude } = this.props;
    if (!this.props.storePage) {
      const { coupons: featuredCoupons } = await fetchCouponsFiltered({
        filters,
        props: { longitude, latitude, featured: true },
      });
      this.setState({ featuredCoupons });
    }
    const page = 1;
    const { coupons, totalRecords } = await fetchCouponsFiltered({
      filters,
      page,
      props: { longitude, latitude, featured: false, showcased: this.props.storePage },
    });

    if (this.props.neverEmpty && coupons.length === 0) {
      await this.clearFilters();
    } else {
      this.setState({
        coupons,
        page,
        totalRecords,
        loading: false,
      });
    }
  };

  loadMore = async () => {
    const { coupons, page, filters } = this.state;
    const { longitude, latitude } = this.props;
    const nextPage = page + 1;

    const { coupons: newCoupons, totalRecords } = await fetchCouponsFiltered({
      filters,
      page: nextPage,
      totalRecords,
      props: { longitude, latitude, featured: false, showcased: this.props.storePage },
    });
    const allCoupons = coupons.concat(newCoupons);
    this.setState({ coupons: allCoupons, page: nextPage });
  };

  render() {
    const {
      coupons,
      featuredCoupons,
      filters,
      loading,
      totalRecords,
      distance,
    } = this.state;
    return (
      <section className="pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="border-0">
                { this.props.showInactiveHeader && (
                    <div>
                      <h1 className="text-primary text-center">{ this.props.inactiveCouponText}</h1>
                      { coupons.length > 0 && ( <p className="text-primary text-center">{ this.props.inactiveCouponSubText }</p> ) }
                      { coupons.length === 0 && <p className="text-primary text-center">Check back - they'll probably have some more up soon.</p> }
                    </div>
                )}
                {(!this.props.showInactiveHeader || !this.props.filters.stores) && (
                    <header className="card-header p-0 border-0">
                      {!this.props.hideHeader && (<NearMeDealsTitle
                          storeId={this.props.storeId}
                          title={this.props.title}
                          distance={distance}
                      />
                      )}
                      <NearMeDealsFilter
                          setFilter={this.setFilter}
                          removeFilter={this.removeFilter}
                          parentState={this.state}
                          clearFilters={this.clearFilters}
                          storePage={!!this.props.storePage}
                      />
                    </header>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <HideOverflowCoupons
              coupons={coupons}
              featuredCoupons={featuredCoupons}
              hideDistance={!!this.props.storeId}
              showcased={!!this.props.storeId}
              storePage={!!this.props.storePage}
            />
          </div>
          {!loading && Object.keys(filters).length > 0 && coupons.length === 0 && !this.props.showInactiveHeader && (
            <div className="d-flex flex-column align-items-center">
              <button className="btn btn-primary" onClick={this.clearFilters}>
                No results! Try different filters. Click here to reset.
              </button>
            </div>
          )}
          {loading ? (
            <SkeletonLoader height={330} />
          ) : (
              (!this.props.showInactiveHeader && coupons.length < totalRecords) && (
              <div className="row">
                <div className="w-100 text-center clearfix see-more">
                  <button
                    onClick={this.loadMore}
                    className="btn btn-primary text-white px-6"
                  >
                    Load More
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </section>
    );
  }
}

export default NearMeDeals;
