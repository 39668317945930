import React, { useState, useEffect } from "react";
import { SkeletonLoader } from "../lib/utils";
import SmallStackedCouponCarousel from "./SmallStackedCouponCarousel";
import { fetchLatestCoupons } from "../lib/api";
import MediaQuery from "react-responsive";

export default function HomePageLatestDeals({ isMobile, latitude, longitude }) {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiCall({ latitude, longitude });
  }, [longitude, latitude]);

  const apiCall = async ({ latitude, longitude }) => {
    setLoading(true);
    let newCoupons = await fetchLatestCoupons({
      latitude,
      longitude,
    });
    newCoupons = newCoupons.sort((a, b) => {
      if (a.created_at < b.created_at) {
        return -1;
      }
      if (a.publish_start > b.publish_start) {
        return 1;
      }
      return 0;
    });
    setCoupons(newCoupons);
    setLoading(false);
  };

  if (loading) {
    return (
      <MediaQuery
        minWidth={isMobile ? 0 : 768}
        maxWidth={isMobile ? 768 : null}
      >
        <div className="container">
          <SkeletonLoader height={200} />
        </div>
      </MediaQuery>
    );
  }
  if (coupons.length === 0) {
    return <div></div>;
  }

  return (
    <MediaQuery minWidth={isMobile ? 0 : 768} maxWidth={isMobile ? 768 : null}>
      <div className={`container ${isMobile ? "py-4" : "py-8"}`}>
        <div className="horizontal-card">
          <h2 className="section-header d-flex align-items-center">
            Latest Weed Deals
            <a
              className="text-muted ml-auto view-all-link"
              href="/newest-marijuana-deals"
            >
              View All
            </a>
          </h2>
          <div className={`py-2`}>
            <SmallStackedCouponCarousel coupons={coupons} />
          </div>
        </div>
      </div>
    </MediaQuery>
  );
}
