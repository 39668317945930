import React from "react";
import { fetchLocal } from "../lib/api";
import { SkeletonLoader } from "../lib/utils";
import { useMediaQuery } from "react-responsive";
import HideOverflowCoupons from "./HideOverflowCoupons";
import NearMeDeals from "./NearMeDeals";

export default class LocalDeals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      page: 1,
      totalRecords: 0,
      coupons: [],
    };
  }
  async componentDidMount() {
    await this._fetchCoupons();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.latitude !== this.props.latitude ||
      prevProps.longitude !== this.props.longitude
    ) {
      await this._fetchCoupons();
    }
  }
  _fetchCoupons = async () => {
    this.setState({ loading: true });
    const { longitude, latitude } = this.props;
    const page = 1;
    const state = this.props.state;
    const { coupons, totalRecords } = await fetchLocalCoupons({
      page,
      state,
      props: { longitude, latitude },
    });
    this.setState({
      coupons,
      page,
      totalRecords,
      loading: false,
    });
  };
  loadMore = async () => {};
  render() {
    const { coupons, loading, totalRecords } = this.state;
    return (
      <section className="pt-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="border-0">
                <header className="card-header p-0 border-0 text-capitalize">
                  <h1 className="section-header h4 text-capitalize font-weight-bold text-primary mb-2">
                    {this.props.state}{" "}
                    {this.props.city && `- ${this.props.city}`} Weed Deals
                  </h1>
                </header>
              </div>
            </div>
          </div>

          {coupons.length > 0 ? (
            <React.Fragment>
              <div className="row">
                <HideOverflowCoupons
                  coupons={coupons}
                  hideDistance={false}
                  showcased={false}
                  storePage={false}
                />
              </div>
              {loading ? (
                <SkeletonLoader height={330} />
              ) : (
                coupons.length < totalRecords && (
                  <div className="row">
                    <div className="w-100 text-center clearfix see-more">
                      <button
                        onClick={this.loadMore}
                        className="btn btn-primary text-white px-6"
                      >
                        Load More
                      </button>
                    </div>
                  </div>
                )
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div>
                <p>
                  Sorry, we don't list any weed deals in {this.props.state} (for
                  now)! A road trip is in your future, perhaps?
                </p>
              </div>
              <NearMeDeals title={"Weed Deals Nearest Me"} />
            </React.Fragment>
          )}
        </div>
      </section>
    );
  }
}
