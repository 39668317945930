import React from "react";

export default function DiscountNumber({ coupon }) {
  const convertCurrency = new Intl.NumberFormat("en-US", {
    currency: "USD",
    style: "currency",
  });
  let partOne = null;
  if (coupon.discount && !coupon.display_discount) {
    partOne = (
      <del className="text-danger font-weight-normal mr-2">
        {convertCurrency.format(
          parseFloat(coupon.price) + parseFloat(coupon.discount_number)
        )}
      </del>
    );
  }
  let discount = null;
  if (coupon.display_discount) {
    if (coupon.discount_number > 0) {
      discount = convertCurrency.format(coupon.discount_number);
    } else if (isNaN(discount)) {
      discount = convertCurrency.format(coupon.discount);
    } else {
      discount = coupon.discount;
    }
  } else {
    discount =
      parseInt(coupon.price) > 0 ? convertCurrency.format(coupon.price) : "";
  }
  let varies = null;
  if (coupon.price == 0 && !coupon.discount) {
    varies = "Varies";
  }
  return (
    <React.Fragment>
      {partOne}
      {discount}
      {varies}
    </React.Fragment>
  );
}
