window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());

// turbolinks:load
document.addEventListener("turbolinks:load", function(event) {
  gtag("config", `${process.env.GOOGLE_TRACKING_ID}`, {
    page_location: event.data.url,
    page_title: event.target.title,
  });
});
