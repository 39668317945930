import React from "react";
import PropTypes from "prop-types";
import axios from "axios";
import CouponCarousel from "./CouponCarousel";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

class HomePageCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      loading: true,
    };
  }
  async componentDidMount() {
    const { type, homepageId } = this.props;
    const res = await axios.get(
      `${process.env.API_HOST}/coupons/${type}?id=${homepageId}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const { coupons } = res.data;
    this.setState({ coupons, loading: false });
  }
  render() {
    const { coupons, loading } = this.state;
    if (loading) {
      return (
        <SkeletonTheme color="lightGray" className="w-100">
          <div className="row w-100">
            <div className="col">
              <Skeleton height={330} width={`100%`} />
            </div>
            <div className="col">
              <Skeleton height={330} width={`100%`} />
            </div>
            <div className="col">
              <Skeleton height={330} width={`100%`} />
            </div>
            <div className="col">
              <Skeleton height={330} width={`100%`} />
            </div>
          </div>
        </SkeletonTheme>
      );
    }
    return <CouponCarousel coupons={coupons} />;
  }
}

// HomePageCoupons.propTypes = {
//   type: PropTypes.string,
//   homepageId: PropTypes.number,
// };

export default HomePageCoupons;
