// @usage getMapboxGl().then(mapbox => ...)
export function getMapboxGl(beta = false) {
  // (url: String) => Promise[Event]
  function loadLink (url) {
    return new Promise((resolve, reject) => {
      const link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = url
      link.addEventListener('error', reject)
      link.addEventListener('load', resolve)
      document.head.appendChild(link)
    })
  }

  function loadScript (url) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = url
      script.addEventListener('error', reject)
      script.addEventListener('load', () => resolve(getMapboxGl()))
      document.head.appendChild(script)
    })
  }

  function get() {
    return new Promise((resolve, reject) => {
      if (window.mapboxgl) {
        resolve(window.mapboxgl)
      } else {
        reject()
      }
    })
  }

  let version = beta ? 'v2.6.0-beta.1' : 'v2.0.0';

  return new Promise(
    (resolve, reject) => get()
      .then(resolve)
      .catch(() => {
        new Promise((resolve) => {
          loadLink(`//api.mapbox.com/mapbox-gl-js/${version}/mapbox-gl.css`)
          .then(() => loadScript(`//api.mapbox.com/mapbox-gl-js/${version}/mapbox-gl.js`))
          .then(resolve)
        })
        .then((as) => resolve(as))
        .catch(reject)
      })
  )
}

document.addEventListener("turbolinks:load", function() {
  // $("img").lazyload();
  showRating();
  var toggler = document.getElementsByClassName("caret");
  var i;

  for (i = 0; i < toggler.length; i++) {
    toggler[i].addEventListener("click", function() {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    });
  }

  $(".h-card").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    touchMove: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "80px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "80px",
        },
      },
    ],
  });
  // $('.h-card').slick('unslick')
  $(".v-card").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    touchMove: true,
    speed: 500,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "160px",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "120px",
        },
      },
    ],
  });
  $(".s-card").slick({
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    touchMove: true,
    speed: 500,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "100px",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "160px",
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          arrows: false,
          centerMode: true,
          centerPadding: "120px",
        },
      },
    ],
  });

  addressLookup();

  var myDate = new Date($("#countdown").data("date"));
  myDate.setDate(myDate.getDate());
  $("#countdown").countdown(myDate, function(event) {
    $(this).html(
      event.strftime(
        '<div class="timer-wrapper"><div class="time">%D</div><span class="text">days</span></div><div class="timer-wrapper"><div class="time">%H</div><span class="text">hrs</span></div><div class="timer-wrapper"><div class="time">%M</div><span class="text">mins</span></div>'
      )
    );
  });

  $("#mobo-menu").on("click", function() {
    if ($(this).hasClass("mobo-menu-open")) {
      $("body").removeClass("mobo-menu-open");
      $(this).removeClass("mobo-menu-open");
    } else {
      $("body").addClass("mobo-menu-open");
      $(this).addClass("mobo-menu-open");
    }
  });

  $(".close-menu").on("click", function() {
    $("body").removeClass("mobo-menu-open");
    $("#mobo-menu").removeClass("mobo-menu-open");
    $("#navbarCollapse-1").removeClass("show");
  });

  $("#mobo-search").on("click", function() {
    if ($(this).hasClass("mobo-search-open")) {
      $("body").removeClass("body-fixed");
      $(this).removeClass("mobo-search-open");
      $(".mobile-search").removeClass("show");
    } else {
      $("body").addClass("body-fixed");
      $(this).addClass("mobo-search-open");
      $(".mobile-search").addClass("show");
    }
  });

  $("#coupons-filters").on("click", function() {
    if ($(this).hasClass("coupons-filters")) {
      $("body").removeClass("body-fixed");
      $(this).removeClass("coupons-filters");
      $(".top-filter").removeClass("show");
    } else {
      $("body").addClass("body-fixed");
      $(this).addClass("coupons-filters");
      $(".top-filter").addClass("show");
    }
  });

  $(".close-filter").on("click", function() {
    $("body").removeClass("body-fixed");
    $("#coupons-filters").removeClass("coupons-filters");
    $(".top-filter").removeClass("show");
  });

  $(".card-group-item .filter-content a").click(function() {
    $(".card-group-item .filter-content a").removeClass("active");
    $(this).addClass("active");
  });

  $(".rating-write").empty();
  $(".rating-write").starRating({
    ratedColor: "gold",
    starSize: 17,
    starGradient: {
      start: "#15723C",
      end: "#15723C",
    },
    minRating: 0.5,
    callback: function(currentRating, cid) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: "/rating/" + cid,
        data: { stars: currentRating },
        success: function(data) {
          $(".rating_count").html(data.rating_count);
        },
        error: function(data) {
          alert("You have already rated this coupon");
        },
      });
    },
  });

  $(".blog-rating-write").starRating({
    ratedColor: "gold",
    starSize: 17,
    starGradient: {
      start: "#15723C",
      end: "#15723C",
    },
    minRating: 0.5,
    callback: function(currentRating, cid) {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: "/cannabis-news/rating/",
        data: { stars: currentRating, id: cid },
        success: function(data) {
          $(".blog_rating_count").html("(" + data.rating_count + " Reviews)");
        },
        error: function(data) {
          alert("You have already rated this coupon");
        },
      });
    },
  });

  $(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 0) {
      $(".top-header").addClass("fixheader");
    } else {
      $(".top-header").removeClass("fixheader");
    }
  });

  if($('a#map-tab').length) {
    $('a#map-tab').on('shown.bs.tab', function (e) {
      let containerWidth = $('.container').width();
      let padding = 20;
      let tabWidth = containerWidth - padding;
      $('#map-canvas').width(tabWidth);
      getMapboxGl().then(() => {
        initMap();
      })
    });
  } else {
    if ($("#map-canvas").length) {
      getMapboxGl().then(() => {
        initMap();
      })
    }
  }
});

var map;
var map_latitude = undefined;
var map_longitude = undefined;
var map_img = undefined;
var map_url = undefined;

function initMap() {
  if ($("#map-lat-log")["length"] == 1) {
    var deal_vector = $("#map-lat-log");
    map_longitude = deal_vector.data("longitude");
    map_latitude = deal_vector.data("latitude");
    map_img = deal_vector.data("img");
    map_url = deal_vector.data("mapurl");
  }

  if (map_latitude == undefined || map_longitude == undefined) {
    setTimeout(initMap, 200);
    return;
  }
  var vector = {
    lat: parseFloat(map_latitude),
    lng: parseFloat(map_longitude),
  };

  mapboxgl.accessToken =
    "pk.eyJ1IjoiZXJpYy1lbnRyaXNpb24iLCJhIjoiY2tqOGdud3JnMHd1bzJycDVieGdnOXEzaSJ9.bUAgjuKaZCHOTmNH1NAL5Q";
  var map_box = new mapboxgl.Map({
    container: "map-canvas",
    style: "mapbox://styles/mapbox/streets-v11",
    center: [parseFloat(map_longitude), parseFloat(map_latitude)],
    zoom: 18,
  });
  map_box.addControl(new mapboxgl.NavigationControl());
  map_box.setStyle("mapbox://styles/mapbox/light-v10");

  var markerContent = document.createElement("div");
  markerContent.innerHTML =
    '<a href="' +
    map_url +
    '" target="_blank">' +
    '<div class="marker">' +
    '<div class="marker-wrapper">' +
    '<div class="pin">' +
    '<div class="image" style="background-position: center; background-image: url(' +
    map_img +
    ');"></div>' +
    "</div>" +
    "</div>" +
    "</div>" +
    "</a>";
  var map_box_marker = new mapboxgl.Marker(markerContent)
    .setLngLat([parseFloat(map_longitude), parseFloat(map_latitude)])
    .addTo(map_box);
}

export function showRating() {
  // $(".rating-show").empty();
  $(".rating-show").starRating({
    ratedColor: "gold",
    starSize: 17,
    starGradient: {
      start: "#15723C",
      end: "#15723C",
    },
    readOnly: true,
  });
}

export function addressLookup() {
  $(".user-location").each(function() {
    var places = new google.maps.places.Autocomplete(this);
    var cityclass = $(this).data("cityclass");
    var stateclass = $(this).data("stateclass");
    var zipclass = $(this).data("zipclass");
    var countryclass = $(this).data("countryclass");
    var latclass = $(this).data("latclass");
    var lngclass = $(this).data("logclass");
    google.maps.event.addListener(places, "place_changed", function() {
      var place = places.getPlace();
      if (place.geometry) {
        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
      }
      var rquiredAddress = place.formatted_address.slice(
        0,
        place.formatted_address.lastIndexOf(",")
      );
      for (var i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];

        if (addressType == "locality") {
          var city = place.address_components[i]["long_name"];
        } else if (addressType == "administrative_area_level_1") {
          var state = place.address_components[i]["long_name"];
        } else if (addressType == "country") {
          var country = place.address_components[i]["long_name"];
        } else if (addressType == "postal_code") {
          var postalcode = place.address_components[i]["long_name"];
        }
      }
      if (cityclass && stateclass) {
        $("." + cityclass).val(city);
        $("." + stateclass).val(state);
      }
      if (countryclass) {
        $("." + countryclass).val(country);
      }
      if (zipclass) {
        $("." + zipclass).val(postalcode);
      }
      if (latclass && lngclass && lat && lng) {
        $("." + latclass).val(lat);
        $("." + lngclass).val(lng);
      }
    });

    google.maps.event.addDomListener(this, "keydown", function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });
  });
}
