import React from "react";
import "./NearMeDealsTitle.scss";

// TODO add distance
export default function NearMeDealsTitle({ distance, storeId, title }) {
  let text = title || "Marijuana Deals Near Me";
  return (
    <h1 className="section-header h4 text-capitalize font-weight-bold text-primary mb-2">
      {text}
      {distance && <span className="distance">{distance.toFixed(2)} mi</span>}
    </h1>
  );
}
