import React, { useEffect, useState } from "react";
import Storage from "./Storage";
import LocationSearch from "./LocationSearch";
import ServiceAd from "./ServiceAd";
import NearMeDeals from "./NearMeDeals";
import { isNil } from "lodash";

export default function LocalPage({
  state,
  city,
  storeId,
  title,
  filters,
  storePage,
  localPlace,
  ...props
}) {
  useEffect(() => {
  }, []);

  if (isNil(localPlace)) {
    return <div></div>;
  }

  const mainMobileAd = props.mainMobileAd && props.mainMobileAd === true;
  return (
    <Storage
      locationName={localPlace.label}
      longitude={localPlace.longitude}
      latitude={localPlace.latitude}
      overrideStoredLocation={true}
    >
      <LocationSearch />
      <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
        <div className="container">
          <ServiceAd zone={"Top-B(600*100)"} />
        </div>
      </div>
      {mainMobileAd && (
        <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
          <div className="container">
            <ServiceAd zone={props.adZone} />
          </div>
        </div>
      )}
      <NearMeDeals
        storeId={storeId}
        title={title}
        filters={filters}
        storePage={storePage}
        neverEmpty={true}
      />
    </Storage>
  );
}
