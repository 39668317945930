import React from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { isNil } from "lodash";

class ServiceAd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {},
      loading: true,
    };
  }
  async componentDidMount() {
    const { zone } = this.props;
    const res = await axios.get(`${process.env.API_HOST}/services/${zone}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const { service } = res.data;
    this.setState({ service, loading: false });
  }
  render() {
    const { service, loading } = this.state;
    if (loading || isNil(service)) {
      return <div></div>;
    }
    const { footer } = this.props;
    return (
      <div
        className={`z-banner d-flex justify-content-center mt-2 mt-md-0 mt-lg-0 mt-xl-0 ${footer &&
          "z-before-footer mx-2"}`}
      >
        <a
          href={`/pages/${service.id}/advertise_service`}
          className="d-inline-block text-center"
          target={service.target}
          rel={service.rel}
        >
          {service.image ? (
            <img
              src={service.image}
              title={service.title}
              alt={service.title}
              width="720"
              height="90"
            />
          ) : (
            <p>{service.title}</p>
          )}
        </a>
      </div>
    );
  }
}
ServiceAd.propTypes = {
  zone: PropTypes.string.isRequired,
};

export default ServiceAd;
