import React from "react";
import Storage from "./Storage";
import LocationSearch from "./LocationSearch";

export default function LocationSearchOnly({
                                       hideHeader,
                                       loading,
                                       ...props
                                   }) {
    return (
            <Storage>
                {!hideHeader && (<LocationSearch />)}
            </Storage>
            );
}
