import React from "react";
import "./Sidebar.scss";

export default function Sidebar({ title, show, closeSidebar, children }) {
  const drawerClasses = show ? "side-drawer open" : "side-drawer";
  return (
    <div className={drawerClasses} id="location-side-drawer">
      <header className="card-header">
        <div className="d-flex flex-row justify-content-between">
          <h4 className="section-header h4 text-capitalize font-weight-bold text-dark mb-2">
            {title}
          </h4>
          <span id="close-side-bar" className="p-2 close-filter" onClick={closeSidebar}>
            <i className="fas fa-times"></i>
          </span>
        </div>
      </header>
      <div className="card-body">{children}</div>
    </div>
  );
}
