import React from "react";
import Storage from "./Storage";
import HomePageLatestDeals from "./HomePageLatestDeals";
import AboveFooterAdService from "./AboveFooterAdService";

export default function HomePagePart2() {
    return (
        <Storage>
            <HomePageLatestDeals isMobile={true} />
            <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block py-3 bg-light">
                <div className="container">
                    <AboveFooterAdService zone={"Above-Footer-Desktop"} footer={true} />
                </div>
            </div>
            <HomePageLatestDeals isMobile={false} />
        </Storage>
    );
}
