import { reverseGeocode } from '../lib/api';
import moment from "moment";
import { getCurrentPosition } from '../lib/utils';

export class ChangeLocationButton {
    static setup() {
        $(document).on('turbolinks:load', async function () {
            $('#current-location-link').click( () => {
              $('#location-side-drawer').addClass('open');
                $("#close-side-bar").on("click", function() {
                    $('#location-side-drawer').removeClass('open');
                });
            })
        });

    }
}
