import { getMapboxGl } from './custom';

document.addEventListener('turbolinks:load', function() {
  if ($('#featured-store-map-canvas').length) {
    if (window.location.hash === '#map-view') {
      $('a[href="#map-view"]').tab('show');
      setTimeout(() => window.dispatchEvent(new Event('resize')), 200);
    }

    getMapboxGl(true).then(() => {
      initializeFeaturedStoresMap();
    });
  }

  $('#featured-map-tab').click(() => {
    // Ensure window resizes properly when switching tabs and add hash for reloads
    window.location.hash = '#map-view';
    setTimeout(() => window.dispatchEvent(new Event('resize')), 250);
  });

  $('#featured-list-tab').click(() => window.location.hash = '');
});

function initializeFeaturedStoresMap() {
  let map_latitude;
  let map_longitude;

  const mapPoints = document.querySelectorAll('#map-lat-log');
  if (mapPoints.length) {
    // First store is closest, use for initial map box
    map_longitude = mapPoints[0].dataset.longitude;
    map_latitude = mapPoints[0].dataset.latitude;
  }

  if (map_latitude == undefined || map_longitude == undefined) {
    setTimeout(initializeFeaturedStoresMap, 200);
    return;
  }

  mapboxgl.accessToken =
    'pk.eyJ1IjoiZXJpYy1lbnRyaXNpb24iLCJhIjoiY2tqOGdud3JnMHd1bzJycDVieGdnOXEzaSJ9.bUAgjuKaZCHOTmNH1NAL5Q';
  var map_box = new mapboxgl.Map({
    container: 'featured-store-map-canvas',
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [parseFloat(map_longitude), parseFloat(map_latitude)],
    cooperativeGestures: true,
    zoom: 12,
  });
  map_box.addControl(new mapboxgl.FullscreenControl());
  map_box.addControl(new mapboxgl.NavigationControl());
  map_box.addControl(
    new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    })
  );
  map_box.setStyle("mapbox://styles/mapbox/light-v10");
  
  // Use single finger scroll when map is in full-screen mode on mobile (android only)
  map_box.on('resize', () => {
    if (document.fullscreenElement && document.fullscreenElement.id === 'featured-store-map-canvas') {
      map_box._cooperativeGestures = false;
    } else {
      map_box._cooperativeGestures = true;
    }
  });
  
  mapPoints.forEach((point) => plotMapPoint(map_box, point.dataset));
}

function plotMapPoint(map, pointData) {
  var markerContent = document.createElement("div");
  const { mapurl, img, longitude, latitude } = pointData;
  markerContent.innerHTML =
    `<a href="${mapurl}" target="_blank">
      <div class="marker-small">
        <div class="marker">
          <div class="marker-wrapper">
            <div class="pin">
              <div class="image" style="background-position: center; background-image: url(${img});"></div>
            </div>
          </div>
        </div>
      </div>
    </a>`;
  new mapboxgl.Marker(markerContent)
    .setLngLat([parseFloat(longitude), parseFloat(latitude)])
    .addTo(map);
}

