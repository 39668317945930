import axios from "axios";
import { isNil } from "lodash";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};
const mapboxAccessToken =
  "pk.eyJ1IjoiZXJpYy1lbnRyaXNpb24iLCJhIjoiY2tqOGdud3JnMHd1bzJycDVieGdnOXEzaSJ9.bUAgjuKaZCHOTmNH1NAL5Q";

export const apiFilters = {
  RECENTLY_PUBLISHED: "recently_published",
  EXPIRING_SOON: "expiring_soon",
  RECREATIONAL: "recreational",
  MEDICAL: "medical",
  DELIVERY_ONLY: "delivery_only",
  PRICE_MAX: "price_max",
  PRICE_MIN: "price_min",
  PRICE: "price",
  FEATURED: "featured",
  CATEGORIES: "categories",
  STORES: "stores",
};

export const apiFilterNames = {
  recently_published: "New",
  expiring_soon: "Expires Soon",
  medical: "Medical",
  recreational: "Recreational",
  delivery_only: "Delivery",
  price_max: "Less than",
  price_min: "Greater than",
  price: { min: "Less than", max: "Greater than" },
};

export const fetchCategories = async () => {
  const res = await axios.get(`${process.env.API_HOST}/categories`, {
    headers: headers,
  });
  return res.data || [];
};

export const fetchStores = async ({ latitude, longitude }) => {
  let url = `${process.env.API_HOST}/stores`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `?longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { stores } = res.data;
  return stores || [];
};

export const fetchStoreDistance = async ({ storeId, latitude, longitude }) => {
  let url = `${process.env.API_HOST}/stores/${storeId}`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `?longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { distance } = res.data;
  return distance;
};

export const fetchCouponsFiltered = async ({ filters, page, props }) => {
  const { latitude, longitude, featured, showcased } = props;
  let currentPage = page || 1;
  const data = {
    filters: filters,
    page: currentPage,
    latitude,
    longitude,
    featured: !isNil(featured) && featured === true,
    showcased: !isNil(showcased) && showcased === true,
  };
  const res = await axios.post(
    `${process.env.API_HOST}/coupons/filters`,
    data,
    {
      headers: headers,
    }
  );
  const { coupons, total_records } = res.data;
  return { coupons, totalRecords: total_records };
};

export const fetchLocalCoupons = async ({ state, page, props }) => {
  const { latitude, longitude } = props;
  let currentPage = page || 1;
  const data = {
    state,
    page: currentPage,
    latitude,
    longitude,
  };
  const res = await axios.post(`${process.env.API_HOST}/coupons/local`, data, {
    headers: headers,
  });
  const { coupons, total_records } = res.data;
  return { coupons, totalRecords: total_records };
};

export const fetchLatestCoupons = async ({ latitude, longitude }) => {
  let url = `${process.env.API_HOST}/coupons/latest_deals`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `?longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { coupons } = res.data;
  return coupons || [];
};

export const fetchCategoryCouponsBySlug = async ({
  slug,
  latitude,
  longitude,
}) => {
  let url = `${process.env.API_HOST}/coupons/category_coupons?slug=${slug}`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `&longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { coupons } = res.data;
  return coupons || [];
};

export const fetchHomepageFeaturedCoupons = async ({ latitude, longitude }) => {
  let url = `${process.env.API_HOST}/coupons/featured`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `?longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { coupons } = res.data;
  return coupons || [];
};

export const fetchHomepageStoreLocations = async ({ latitude, longitude }) => {
  let url = `${process.env.API_HOST}/homepage_settings/stores`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `?longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { stores } = res.data;
  return stores || [];
};

export const fetchHomepageCustomCoupons = async ({
  endpoint,
  latitude,
  longitude,
}) => {
  let url = `${process.env.API_HOST}/coupons/${endpoint}`;
  if (!isNil(longitude) && !isNil(latitude)) {
    url += `?longitude=${longitude}&latitude=${latitude}`;
  }
  const res = await axios.get(url, {
    headers: headers,
  });
  const { coupons } = res.data;
  return coupons || [];
};

export const mapboxSearch = async (text) => {
  const friendlyText = encodeURI(text);
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${friendlyText}.json?access_token=${mapboxAccessToken}&autocomplete=true&country=US&types=place,neighborhood,address`;
  const res = await axios.get(url);
  const { features } = res.data;
  return features.map((row) => ({
    id: row.id,
    label: row.place_name,
    value: row,
    coordinates: row.center,
  }));
};

export const reverseGeocode = async ({ latitude, longitude }) => {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxAccessToken}`;
  const res = await axios.get(url);
  const { features } = res.data;
  if (features.length > 0) {
    return features[0].place_name;
  }
  return "";
};

export const fetchLocationFromIP = async () => {
  let url = `${process.env.API_HOST}/locations/me`;
  const res = await axios.get(url, {
    headers: headers,
  });
  return res.data;
};

export const updateSessionLocation = async ({ latitude, longitude, location_string }) => {
  let url = `${process.env.API_HOST}/locations`;
  const res = await axios.put(url, {
    headers: headers,
    location: { latitude, longitude, location_string  }
  });
  return res.data;
};
