import React from "react";
import Storage from "./Storage";
import NearMeDeals from "./NearMeDeals";

export default function NearMePage({
  storeId,
  title,
  filters,
  storePage,
  showInactiveHeader,
  hideHeader,
  inactiveCouponText,
  inactiveCouponSubText,
  ...props
}) {
  return (
    <Storage>
      <NearMeDeals
        inactiveCouponText={inactiveCouponText}
        inactiveCouponSubText={inactiveCouponSubText}
        showInactiveHeader={showInactiveHeader}
        hideHeader={hideHeader}
        storeId={storeId}
        title={title}
        filters={filters}
        storePage={storePage}
      />
    </Storage>
  );
}
