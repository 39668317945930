window.CSLib = {};

document.addEventListener("turbolinks:load", function () {
    var user_location;
    user_location = $("user_location").first();
    CSLib.user_location = [user_location.data('lat'), user_location.data('lng')];
    CSLib.user_location_string = user_location.data('string');
    CSLib.update_location_with_current_loc = function() {
        return navigator.geolocation.getCurrentPosition(function(position) {
            var form;
            form = $("#location_update_form").first();
            form.find("input[name=latitude]").val(position.coords.latitude);
            form.find("input[name=longitude]").val(position.coords.longitude);
            return form.submit();
        });
    };
    if (!user_location.data("lat") && !user_location.data("lng")){
        CSLib.update_location_with_current_loc();
    }
});
