import React, { useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { apiFilters, apiFilterNames } from "../lib/api";
import "./NearMeDealsFilter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import FadeLoader from "react-spinners/FadeLoader";
import { isNil } from "lodash";

const priceRanges = [
  { min: 0, max: 5, name: "$0 to $5" },
  { min: 5, max: 25, name: "$5 to $25" },
  { min: 25, max: 50, name: "$25 to $50" },
  { min: 50, max: 100, name: "$50 to $100" },
  { min: 100, name: "Greater than $100" },
];

const truncateString = function(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

const DropDownPriceMenu = ({ setFilter }) => {
  return (
    <div className="dropdown">
      <button
        className="filter-btn"
        type="button"
        id="dropdownPriceFilterMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Price Range
        <i className="fas fa-angle-down small ml-1"></i>
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownPriceFilterMenu">
        {priceRanges.map(({ min, max, name }, index) => (
          <button
            key={index}
            className="dropdown-item"
            onClick={setFilter({
              [apiFilters.PRICE]: { min, max, name },
            })}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
};

const DropDownCategoryMenu = ({ categories, setFilter }) => {
  return (
    <div className="dropdown">
      <button
        className="filter-btn"
        type="button"
        id="dropdownCategoryFilterMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Category
        <i className="fas fa-angle-down small ml-1"></i>
      </button>
      <div
        className="dropdown-menu scrollable-menu"
        aria-labelledby="dropdownCategoryFilterMenu"
      >
        {categories.map(({ id, name }, index) => (
          <button
            key={index}
            className="dropdown-item"
            onClick={setFilter({ [apiFilters.CATEGORIES]: id })}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
};

const DropDownStoresMenu = ({ stores, setFilter }) => {
  return (
    <div className="dropdown">
      <button
        className="filter-btn"
        type="button"
        id="dropdownStoreFilterMenu"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Stores
        <i className="fas fa-angle-down small ml-1"></i>
      </button>
      <div
        className="dropdown-menu dropdown-menu-stores scrollable-menu"
        aria-labelledby="dropdownStoreFilterMenu"
      >
        {stores.map(({ id, name, distance }, index) => (
          <button
            key={index}
            className="dropdown-item"
            onClick={setFilter({ [apiFilters.STORES]: id })}
          >
            {truncateString(name, 25)}{" "}
            <span className="font-italic">
              {distance ? `${distance.toFixed(2)} mi` : ""}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

const ActiveFilter = ({ removeFilter, filterKey, filterValue }) => {
  let title = apiFilterNames[filterKey];
  if (filterKey === apiFilters.PRICE) {
    title = `${filterValue["name"]}`;
  } else if (
    filterKey === apiFilters.CATEGORIES ||
    filterKey === apiFilters.STORES
  ) {
    title = `${filterValue}`;
  }
  if (
    filterKey === apiFilters.LOCAL_STATE ||
    filterKey === apiFilters.LOCAL_CITY
  ) {
    return <div></div>;
  }
  if (title.length === 0) {
    return <FadeLoader color={"#2AA35C"} loading={true} size={10} />;
  }
  return (
    <div className="active-filter" onClick={removeFilter}>
      <FontAwesomeIcon
        icon={faTimesCircle}
        size="2x"
        className="active-filter-icon"
      />
      <div className="active-filter-text-wrapper">
        <p className="active-filter-text">{title}</p>
      </div>
    </div>
  );
};

const ActiveFilters = ({ filters, removeFilter, parentState, storePage }) => {
  return Object.keys(filters)
    .filter((filterKey) => !(storePage && filterKey === apiFilters.STORES))
    .map((filterKey, i) => {
      if (
        filterKey === apiFilters.CATEGORIES ||
        filterKey === apiFilters.STORES
      ) {
        return filters[filterKey].map((id) => {
          const list =
            filterKey === apiFilters.STORES
              ? parentState.stores
              : parentState.categories;
          const obj = list.find((obj) => obj.id === id);
          const name = obj ? obj.name : "";
          return (
            <ActiveFilter
              key={`${filterKey}-${id}`}
              filterKey={filterKey}
              filterValue={name}
              removeFilter={removeFilter({ filterKey, id })}
            />
          );
        });
      } else if (filterKey === apiFilters.PRICE) {
        return filters[filterKey].map((priceRange, j) => (
          <ActiveFilter
            key={`price-range-${j}`}
            filterKey={filterKey}
            filterValue={priceRange}
            removeFilter={removeFilter({ filterKey, id: priceRange["min"] })}
          />
        ));
      } else {
        return (
          <ActiveFilter
            key={i}
            filterKey={filterKey}
            filterValue={filters[filterKey]}
            removeFilter={removeFilter({ filterKey })}
          />
        );
      }
    });
};

export default function NearMeDealsFilter({
  setFilter,
  parentState,
  clearFilters,
  removeFilter,
  storePage,
}) {
  // const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  const { filters } = parentState;
  const [showSideBar, setShowSideBar] = useState(false);
  const numberOfActiveFilters = () => {
    let count = 0;
    Object.keys(filters)
      .filter((filterKey) => !(storePage && filterKey === apiFilters.STORES))
      .forEach((filterKey) => {
        if (
          filterKey === apiFilters.CATEGORIES ||
          filterKey === apiFilters.STORES ||
          filterKey === apiFilters.PRICE
        ) {
          if (!isNil(filters[filterKey])) {
            count += filters[filterKey].length;
          }
        } else {
          count += 1;
        }
      });
    if (count > 0) {
      return ` (${count} ACTIVE) `;
    }
    return "";
  };
  return (
    <React.Fragment>
      <div className="d-none d-md-flex flex-column">
        <div className="d-flex flex-row flex-wrap justify-content-start">
          <button className="filter-btn pl-0" onClick={clearFilters}>
            All
          </button>

          <button
            className="filter-btn"
            onClick={setFilter({ [apiFilters.RECENTLY_PUBLISHED]: null })}
          >
            New
          </button>

          <button
            className="filter-btn"
            onClick={setFilter({ [apiFilters.EXPIRING_SOON]: null })}
          >
            Expires Soon
          </button>

          <button
            className="filter-btn"
            onClick={setFilter({ [apiFilters.MEDICAL]: null })}
          >
            Medical
          </button>

          <button
            className="filter-btn"
            onClick={setFilter({ [apiFilters.RECREATIONAL]: null })}
          >
            Recreational
          </button>

          <button
            className="filter-btn"
            onClick={setFilter({ [apiFilters.DELIVERY_ONLY]: null })}
          >
            Delivery
          </button>

          <DropDownPriceMenu setFilter={setFilter} />
          <DropDownCategoryMenu
            categories={parentState.categories}
            setFilter={setFilter}
          />
          {!storePage && (
            <DropDownStoresMenu
              stores={parentState.stores}
              setFilter={setFilter}
            />
          )}
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-start py-3">
          <ActiveFilters
            filters={filters}
            removeFilter={removeFilter}
            parentState={parentState}
            storePage={storePage}
          />
        </div>
      </div>
      <div className="d-block d-md-none">
        <div
          className="d-flex flex-column align-items-start filter-link filter-close text-uppercase mb-3"
          onClick={() => setShowSideBar(!showSideBar)}
        >
          <span className="text-dark text-uppercase">
            <i className="mr-2 mt-1 fas fa-sliders-v"></i> Coupon filters
            {numberOfActiveFilters()}
          </span>
        </div>
        <Sidebar
          title={"Coupon filters"}
          show={showSideBar}
          closeSidebar={() => setShowSideBar(false)}
        >
          <div className="w-100 mb-2">
            <button className="filter-btn" onClick={clearFilters}>
              All
            </button>
          </div>
          <div className="w-100 mb-2">
            <button
              className="filter-btn"
              onClick={setFilter({ [apiFilters.RECENTLY_PUBLISHED]: null })}
            >
              New
            </button>
          </div>
          <div className="w-100 mb-2">
            <button
              className="filter-btn"
              onClick={setFilter({ [apiFilters.EXPIRING_SOON]: null })}
            >
              Expires Soon
            </button>
          </div>
          <div className="w-100 mb-2">
            <button
              className="filter-btn"
              onClick={setFilter({ [apiFilters.MEDICAL]: null })}
            >
              Medical
            </button>
          </div>
          <div className="w-100 mb-2">
            <button
              className="filter-btn"
              onClick={setFilter({ [apiFilters.RECREATIONAL]: null })}
            >
              Recreational
            </button>
          </div>

          <div className="w-100 mb-2">
            <DropDownPriceMenu setFilter={setFilter} />
          </div>
          <div className="w-100 mb-2">
            <DropDownCategoryMenu
              categories={parentState.categories}
              setFilter={setFilter}
            />
          </div>

          {!storePage && (
            <div className="w-100 mb-2">
              <DropDownStoresMenu
                stores={parentState.stores}
                setFilter={setFilter}
              />
            </div>
          )}

          <div className="d-flex flex-column justify-content-start pt-3">
            {numberOfActiveFilters() !== 0 && (
              <div>
                <p>Active Filters</p>
              </div>
            )}
            <ActiveFilters
              filters={filters}
              removeFilter={removeFilter}
              parentState={parentState}
              storePage={storePage}
            />
          </div>
        </Sidebar>
      </div>
    </React.Fragment>
  );
}
