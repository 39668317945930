import React from "react";

import "./CouponCarousel.scss";
import { isNil } from "lodash";
import DiscountNumber from "./DiscountNumber";

export default function Coupon({ coupon, featured, hideDistance, showcased }) {
  if (isNil(coupon)) {
    return <div className={`card shadow ${featured && "is_featured"}`}></div>;
  }
  return (
    <div
      className={`card shadow ${((coupon.showcased && showcased) || featured) &&
        "is_featured"}`}
      key={coupon.id}
    >
      <a
        href={`/coupon/${coupon.slug}`}
        className="d-block position-relative"
        title={coupon.name}
      >
        <img
          src={coupon.deal_image}
          className="card-img-top"
          alt={coupon.name}
          title={coupon.name}
          lazy="true"
        />
        {coupon.store_location && (
          <img
            src={coupon.store_location.logo}
            className="card-img-logo"
            alt={coupon.name}
            title={coupon.name}
            lazy="true"
          />
        )}
        {coupon.delivery_only && (
            <small className="card-miles">Delivery</small>
        )}
        {!coupon.delivery_only && coupon.distance && !hideDistance && (
          <small className="card-miles">{coupon.distance.toFixed(2)} mi</small>
        )}
        {featured && <small className="featured-text">Sponsored Result</small>}
      </a>
      <div className="card-body p-2 border-top">
        <h5 className="card-title mb-2">
          <a
            href={`/coupon/${coupon.slug}`}
            className="d-block"
            title={coupon.name}
          >
            {coupon.name.length > 60 ? coupon.name.slice(0, 60) : coupon.name}
          </a>
        </h5>
        {coupon.store_location && (
          <React.Fragment>
            <p className="card-text text-muted mb-1">{coupon.store_location.name}</p>{" "}
            <p className="card-text text-muted mb-1">{`${coupon.store_location.city} - ${coupon.medical_or_recreational}`}</p>
          </React.Fragment>
        )}

        <small className="text-primary font-weight-bolder">
          <DiscountNumber coupon={coupon} />
        </small>
        {coupon.store_location && (
          <small>
            <a
              href={`/dispensaries/${coupon.store_location.state_slug}/${coupon.store_location.city_slug}/${coupon.store_location.slug}`}
              className="text-muted more-deals"
            >
              More deals from this shop
            </a>
          </small>
        )}
      </div>
    </div>
  );
}
