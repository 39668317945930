import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { fetchHomepageFeaturedCoupons } from "../lib/api";
import ServiceAd from "./ServiceAd";
import DiscountNumber from "./DiscountNumber";

const SkeletonLoader = () => {
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
  if (isDesktop) {
    return (
      <SkeletonTheme color="lightGray" className="w-100">
        <div className="d-flex flex-row w-100">
          <div className="w-100 px-1">
            <Skeleton height={404} width={`100%`} />
          </div>
          <div className="d-flex flex-row w-100 px-1">
            <div className="w-100 px-1">
              <Skeleton height={200} width={`100%`} count={2} />
            </div>
            <div className="w-100 px-1">
              <Skeleton height={200} width={`100%`} count={2} />
            </div>
          </div>
        </div>
      </SkeletonTheme>
    );
  }
  return (
    <SkeletonTheme color="lightGray" className="w-100">
      <div className="w-100">
        <Skeleton height={400} width={`100%`} />
      </div>
      <div className="d-flex flex-column w-100">
        <div className="d-flex flex-row w-100 px-1">
          <Skeleton height={200} width={`100%`} />
          <Skeleton height={200} width={`100%`} />
        </div>
        <div className="d-flex flex-row w-100 px-1">
          <Skeleton height={200} width={`100%`} />
          <Skeleton height={200} width={`100%`} />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default function HomePageFeatured({ latitude, longitude }) {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    apiCall({ latitude, longitude });
  }, [latitude, longitude]);

  const apiCall = async ({ latitude, longitude }) => {
    setLoading(true);
    const newCoupons = await fetchHomepageFeaturedCoupons({
      latitude,
      longitude,
    });
    setCoupons(newCoupons);
    setLoading(false);
  };

  const mobileCouponDisplay = (current_index) => {
    if(restCoupons.length === 3 && current_index === 2) {
      return 'd-none d-md-block'
    } else {
      return '';
    }
  }

  if (loading) {
    return (
      <div className="container">
        <SkeletonLoader />
      </div>
    );
  }
  const restCoupons = coupons.slice(1, coupons.length);
  const firstMobileDisplay = restCoupons.length > 1 ? '' : 'd-none d-md-block';
  const restCouponsColumns = restCoupons.length === 2 ? 'col-lg-9 col-md-9 col-sm-6 col-6' : 'col-6 col-sm-6 col-md-6';
  const mainCoupon = coupons[0] ;
  return (
    <section className="top-sec pt-0 pb-4 pb-md-6">
      <div className="container">
        <h1 className="section-header d-flex align-items-center my-1">
          Featured Dispensary Deals & Cannabis Coupons
        </h1>
        <div className="row">
          <div className="col-12 col-md-6">
            <a
              className="bg-white rounded big border shadow overflow-hidden d-block position-relative"
              href={`/coupon/${mainCoupon.slug}`}
              title={mainCoupon.name}
            >
              <img
                src={mainCoupon.deal_image}
                className="card-img-top"
                title={mainCoupon.name}
                alt={mainCoupon.name}
              />

              {mainCoupon.store_location && (
                <img
                  src={mainCoupon.store_location.logo}
                  className="featured-card-img-logo"
                  alt={mainCoupon.name}
                  title={mainCoupon.name}
                  lazy="true"
                />
              )}

              <div className="card-body">
                <h4 className="mb-2 h5 text-truncate text-primary">
                  {mainCoupon.name}
                </h4>
                {mainCoupon.store_location && (
                  <p className="mb-1 text-truncate text-muted">
                    {`${mainCoupon.store_location.city} - ${mainCoupon.medical_or_recreational}`}
                  </p>
                )}
                <small className="text-primary font-weight-bolder">
                  <DiscountNumber coupon={mainCoupon} />
                </small>
                {mainCoupon.distance && (
                  <small className="featured-card-miles text-muted">
                    {mainCoupon.distance.toFixed(2)} mi
                  </small>
                )}
              </div>
            </a>
            <div className="d-block d-sm-block d-md-none d-lg-none d-xl-none">
              <div className="text-center mb-4">
                <ServiceAd zone={"Featured-Mid-Mobile"} />
              </div>
            </div>
          </div>
          <div className={`col-12 col-md-6 ${firstMobileDisplay}`}>
            <div className="row h-100">
              {restCoupons.map((coupon, index) => (
                <div
                  className={`${restCouponsColumns} pl-7 mb-2 ${mobileCouponDisplay(index)}`}
                  key={coupon.id}
                >
                  <a
                    className="bg-white rounded border shadow small overflow-hidden"
                    href={`/coupon/${coupon.slug}`}
                    title={coupon.name}
                  >
                    <img
                      src={coupon.deal_image}
                      className="card-img-top"
                      title={coupon.name}
                      alt={coupon.name}
                    />
                    <div className="card-body">
                      <h4 className="mb-2 text-truncate text-primary">
                        {coupon.name}
                      </h4>
                      {coupon.store_location && (
                        <p className="mb-1 text-truncate text-muted">
                          {`${coupon.store_location.city} - ${coupon.medical_or_recreational}`}
                        </p>
                      )}
                      <small className="text-primary font-weight-bolder">
                        <DiscountNumber coupon={coupon} />
                      </small>
                      {coupon.distance && (
                        <small className="featured-card-miles text-muted">
                          {coupon.distance.toFixed(2)} mi
                        </small>
                      )}
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
