import React from "react";
import { reverseGeocode, fetchLocationFromIP, updateSessionLocation } from "../lib/api";
import { getCurrentPosition } from '../lib/utils';
import { isNil } from "lodash";

async function getPositionPermissions() {
  try {
    return await navigator.permissions.query({ name: "geolocation" });
  } catch (error) {
    return {
      state: "granted",
    };
  }
}

const localStorageKey = "cannasaver-local-storage";

export default class Storage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      latitude: props.latitude || null,
      longitude: props.longitude || null,
      currentLatitude: props.latitude || null,
      currentLongitude: props.longitude || null,
      locationName: props.locationName || "",
      currentLocation: true,
      currentLocationName: "",
    };
  }

  async componentDidMount() {
    if (this.props.overrideStoredLocation !== true) {
      await this.syncCurrentLocation();
    } else {
      this.setState(
        {
          userSetLocation: true,
        },
        this.setLocationInLocalStorage
      );
    }
  }

  syncCurrentLocation = async () => {
    const localState = this.getLocationFromLocalStorage();
    this.setState({ ...this.state, ...localState });
    console.log(
      "sync localState[userSetLocation]",
      localState["userSetLocation"]
    );
    if (isNil(localState["currentLocationName"])) {
      await this.getCityFromIPAddress();
    } else if (localState["userSetLocation"] === false) {
      const locationEnabled = await this.getCurrentLocation();
      if (!locationEnabled) {
        await this.getCityFromIPAddress();
      }
    }
    this.setState({ loading: false });
  };

  getCurrentLocation = async () => {
    if (navigator.geolocation) {
      const result = await getPositionPermissions();
      const currentLocationAvailable = await this.updateLocationFromState(
        result.state
      );
      result.onchange = async (state) => {
        await this.updateLocationFromState(state);
      };
      if (currentLocationAvailable) {
        return true;
      }
    }
    return false;
  };

  updateLocationFromState = async (state) => {
    try {
      if (state === "granted" || state === "prompt") {
        const { coords } = await getCurrentPosition();
        let { latitude, longitude } = coords;
        let reversePlaceName = await reverseGeocode({
          latitude,
          longitude,
        });
        if (isNil(reversePlaceName) || reversePlaceName.length === 0) {
          reversePlaceName =
            "Location associated with geo coordinates is not available.";
        }
        this.setState(
          {
            currentLocationLastFetched: Date.now(),
            currentLatitude: latitude,
            currentLongitude: longitude,
            latitude,
            longitude,
            loading: false,
            locationName: reversePlaceName,
            currentLocationName: reversePlaceName,
            userSetLocation: false,
          },
          this.setLocationInLocalStorage
        );
        await updateSessionLocation({ latitude: this.state.latitude, longitude: this.state.longitude, location_string: this.state.locationName });
        return true;
      }
    } catch (error) {
      console.error("updateLocationFromState error", error);
      this.setState({ loading: false });
    }
    return false;
  };

  getCityFromIPAddress = async () => {
    const res = await fetchLocationFromIP();
    const reversePlaceName = res["name"];
    console.log("getCityFromIPAddress", reversePlaceName);
    const latitude = res["latitude"];
    const longitude = res["longitude"];
    this.setState(
      {
        currentLocationLastFetched: Date.now(),
        currentLatitude: latitude,
        currentLongitude: longitude,
        latitude,
        longitude,
        loading: false,
        locationName: reversePlaceName,
        currentLocationName: reversePlaceName,
        userSetLocation: null,
      },
      this.setLocationInLocalStorage
    );
  };

  setLocationInLocalStorage = () => {
    let updatedStorage = this.syncWithCurrentLocalStorage();
    localStorage.setItem(localStorageKey, JSON.stringify(updatedStorage));
  };

  syncWithCurrentLocalStorage = () => {
    let storage = JSON.parse(localStorage.getItem(localStorageKey)) || {};
    let updatedStorage = {}
    Object.assign(updatedStorage, storage, this.state);
    return updatedStorage;
  }

  getLocationFromLocalStorage = () => {
    let localData = localStorage.getItem(localStorageKey);
    try {
      if (isNil(localData)) {
        localData = {};
      } else {
        localData = JSON.parse(localData);
      }
    } catch (e) {
      localData = {};
    }
    return localData;
  };

  setLocation = ({ locationName, latitude, longitude }) => {
    this.setState(
      { locationName, latitude, longitude, userSetLocation: true },
      this.setLocationInLocalStorage
    );
  };

  setCurrentLocation = async () => {
    const currentLocationAvailalble = await this.getCurrentLocation();
    if (!currentLocationAvailalble) {
      window.alert(
        "Permission denied.\nPlease update your location permission and try again or search for a city or address."
      );
    }
  };

  render() {
    const {
      latitude,
      longitude,
      locationName,
      currentLocation,
      currentLatitude,
      currentLongitude,
      currentLocationName,
    } = this.state;
    const childrenWithProps = React.Children.map(
      this.props.children,
      (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            setLocation: this.setLocation,
            locationName,
            currentLocation,
            setCurrentLocation: this.setCurrentLocation,
            latitude,
            longitude,
            currentLatitude,
            currentLongitude,
            currentLocationName,
          });
        }
        return child;
      }
    );
    console.log("latitude", latitude, "longitude", longitude);
    return <React.Fragment>{childrenWithProps}</React.Fragment>;
  }
}
