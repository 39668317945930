import React, { useState, useEffect } from "react";
import CouponCarousel from "./CouponCarousel";
import { SkeletonLoader } from "../lib/utils";
import { fetchCategoryCouponsBySlug } from "../lib/api";

export default function HomePageCategoryCoupons({
  slug,
  title,
  href,
  longitude,
  latitude,
}) {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiCall({ latitude, longitude });
  }, [longitude, latitude]);

  const apiCall = async ({ latitude, longitude }) => {
    setLoading(true);
    const newCoupons = await fetchCategoryCouponsBySlug({
      slug,
      latitude,
      longitude,
    });
    setCoupons(newCoupons);
    setLoading(false);
  };

  if (loading) {
    return (
      <div className="bg-grey">
        <div className="container">
          <SkeletonLoader height={300} />
        </div>
      </div>
    );
  }
  return (
    <div className="bg-grey">
      <div className="container">
        <h4 className="section-header d-flex align-items-center text-uppercase store-title">
          {title}
          <a href={href} className="text-muted ml-auto view-all-link text-nowrap small">
            View All
          </a>
        </h4>
        <div className="pb-6 mt-3">
          <CouponCarousel coupons={coupons} />
        </div>
      </div>
    </div>
  );
}
